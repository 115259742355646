.registration-page-container {
  width: 100%;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;

  .title-dropdown-container {
    display: flex;
    width: 100%;
    justify-content: left;

    .title-select {
      width: 47%;
    }
  }
  .Mui-error,
  .error-message {
    text-transform: uppercase;
  }

  .registration-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;

    .info-message {
      width: 100%;
      text-align: right;
      font-weight: 400;
      font-size: 10px;
      color: #a5a5a5;

      &.mandatory-message {
        margin-bottom: 10px;
      }

      &.dob-message {
        margin-bottom: -14px;
        width: 70%;
        margin-left: 30%;
      }
    }

    .name-fields-container {
      display: flex;
      justify-content: space-between;
      width: 100%;

      .half-width-field {
        width: 47% !important;
      }
    }

    .confirm-button {
      margin-top: 20px;
    }

    .country-code-select {
      width: 65px;
    }
    .phone-input-with-margin {
      margin-right: 10px;
    }

    .bottom-button {
      margin-top: 40px;
    }
  }
}
