.error-page-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .error-page-messages {
        margin-top: 20vh;
        width: 70%;
        text-align: center;

        .margin-top {
            margin-top: 50px;
        }

        .error-page-message {
            margin-bottom: 10px;
        }
    }
}