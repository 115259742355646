.home-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-bottom: 20px;

    .heading-container {
        margin-top: 50px;

        .header-line-1 {
            margin-bottom: 10px;
        }
    }

    .product-dropdown-container {
        width: 100%;
        margin-top: 50px;

        .product-dropdown {
            width: 100%;
            text-align: center;
        }
    }

    .disclaimer-container {
        margin-top: 50px;
    }

    .agreement-wrapper {
        margin-top: 50px;
    }

    .bottom-button {
        margin-top: 50px;
    }

    .bottom-button-container {
        bottom: 0;
        width: 100%;
        max-width: 500px;
    }

    @media screen and (min-height: 600px)  {
        .bottom-button-container {
        position: fixed;
        padding: 24px;
        }
    }

}

.product-select > div {
    color: #989898;
}
