.container {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: 4px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 2px;

    &.error {
      border: 1px solid #d32f2f;
    }
  }

  /* When the checkbox is checked, add a black background */
  .container input:checked ~ .checkmark {
    background-color: #000000;
  }
  
  @media (prefers-color-scheme: dark) {
    .container input:focus~.checkmark {
      border: 1px solid white;
    }
  }