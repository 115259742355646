// Base CSS goes here : Headings/ Text / Inputs etc
.center {
    text-align: center;
}

h1 {
    font-size: 40px;
    letter-spacing: 6px;
}

h2 {
    font-size: 30px;
    letter-spacing: 3px;
}

h3 {
    font-size: 22px;
    letter-spacing: 4px;
}

h4 {
    font-size: 18px;
    font-weight: 400;
}

h5 {
    font-size: 14px;
}

p {
    font-size: 12px;
}

a {
    text-decoration: underline;
}

.bold {
    font-weight: 600;
}

.MuiTextField-root, .MuiToggleButtonGroup-root {
    margin-bottom: 20px !important;
}

button {
    height: 50px;   
}

.black-container{ 
    height: 100vh;
    background-color: #000000;
    color: #ffffff;
}

ul {
    max-height: 50vh;
}

.MuiInput-underline {
    &:after {
       transition: none !important;
    }
}


.error-message {
    color: #d32f2f;
    font-family: Helvetica,sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
}

.product-menu-item {
    display: flex !important;
    justify-content: center !important;
    margin-right: 20px !important;
    white-space: unset !important;
    text-align: center !important;
}

.select-control > div {
    background-color: transparent !important;
}

.MuiInputAdornment-root > p {
    font-size: 13px !important;
}

.MuiInputLabel-root {
    overflow: visible !important;
}

.MuiInput-underline.Mui-disabled:before {
    border-bottom-style: solid !important;
}