.closed-page-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .closed-page-message {
        margin-top: 30vh;
        width: 70%;
        text-align: center;
    }
}