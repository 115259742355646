.qr-code-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    padding: 40px;

    img {
        width: 200px;
        margin-bottom: 10px;
    }
}