.date-field-container {
    width: 100%;
    display: flex;

    .date-input {
        width: 33%;
    }

    .MuiFormHelperText-root {
        width: 300%;
    }
}