.header-container {
    display: flex;
    flex-direction: column;

    .header-action-container {
        height: 70px;
        padding: 15px 0 30px 0;
    }

    .logo-container {
        display: flex;
        justify-content: center;
        width: 100%;

        img {
            width: 60%;
        }
    }
}

.black-header {
    background-color: #000000;
}

.white-logo {
    width: 70% !important;
}