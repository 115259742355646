
.checkbox-agreement-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  
  .checkbox-agreement-header {
    width: 100%;
    display: flex;
    overflow-wrap: wrap;
    
    .heading-button-container {
      display: flex;
      justify-content: space-between;
      margin-left: 35px;
      width: 100%;
    }
  }

  .expanded-section {
    margin-top: 5px;
    margin-left: 35px;
  }
}

.arrow-up {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid #000000;
  margin-top: 6px;
  margin-right: 8px;
}

.arrow-down {
  width: 0; 
  height: 0; 
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid #000000;
  margin-top: 6px;
  margin-right: 8px;
}